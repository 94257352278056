import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import useDate from '@/services/utils/day';
import handleHospital from "@/services/modules/hospital";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';
pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const ipdStatement = () => {
    const { formatDateWithTime } = useDate();
    const { commaFormat } = figureFormatter();
    const { dateOfBarthConvertToAge } = handleHospital();
    const { getHeader, getFooter } = pdfHeaderFooter();

    const exportToPDF = (company, patientDetails, service, receiptGenerals, total, userName, barcode, qrcode) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 100, 25, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(patientDetails, service, receiptGenerals, total, barcode),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Daily Sales Report'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (patientDetails, service, receiptGenerals, total, barcode) => {
        return [
            {
                text: 'IPD BILL (Statement)',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: `${patientDetails.patient.full_name ? 'Patient Name: ' + patientDetails.patient.full_name : ''}`
                            },

                            {
                                text: `${patientDetails.patient.patient_id ? 'Patient ID: ' + patientDetails.patient.patient_id : ''}`
                            },

                            {
                                text: `${calculateAge(patientDetails.patient.birthday)} ${patientDetails.patient.gender ? '| ' + capitalize(patientDetails.patient.gender) : ''}`
                            },

                            {
                                text: `${patientDetails.patient.mobile_no ? 'Phone No: ' + patientDetails.patient.mobile_no : ''}`
                            },

                            {
                                text: `${patientDetails.patient.full_address ? 'Patient Address: ' + patientDetails.patient.full_address : ''}`
                            }
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: `${patientDetails.admission_no ? 'IPD admission no: ' + patientDetails.admission_no : ''}`
                            },

                            {
                                text: `${patientDetails.admission_date ? 'Admission date: ' + patientDetails.admission_date : ''}`
                            },

                            {
                                text: `${patientDetails.department ? 'Admission Dept.: ' + patientDetails.department.name : ''}`
                            },

                            {
                                text: `${patientDetails.service_resource.name ? 'Bed/Cabin no: ' + patientDetails.service_resource.name : ''}`
                            },

                            {
                                text: `${patientDetails.human_resource.name ? 'Consultant: ' + patientDetails.human_resource.name : ''}`
                            }
                        ]
                    }
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', 'auto', 'auto'],
                    body: getTableBody(service)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },

            {
                columns: [
                    {
                        width: '66%',
                        margin: [0, 45, 0, 0],
                        table: {
                          body: getPaidBody(total, receiptGenerals),
                        },
                    },

                    {
                        alignment: 'right',
                        style: 'tableExample',
                        margin: [40, 10, 0, 0],
                        table: {
                            body: getSummaryBody(total)
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex === 0) ? '#f3f2f7' : null;
                            },
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    }
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 20, 0, 0],
                table: {
                    widths: ['auto', 'auto', 'auto', 'auto', '*', 'auto'],
                    body: getReceiptTableBody(receiptGenerals)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },

            {
                columns: [
                    {
                        width: '66%',
                        margin: [0, 45, 0, 0],
                        stack: [],
                    },

                    {
                        alignment: 'right',
                        style: 'tableExample',
                        margin: [40, 10, 0, 0],
                        table: {
                            body: getFinalSummaryBody(total, receiptGenerals)
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex === 0) ? '#f3f2f7' : null;
                            },
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    }
                ]
            },
        ]
    }

    const getReceiptTableBody = (receiptGenerals) => {
        const header = getReceiptTableHead();
        const row = getReceiptTableRow(receiptGenerals);

        return [
            header,
            ...row
        ]
    }

    const getPaidBody = (total, receiptGenerals) => {
        const netAmount = total.amount - (total.discount + total.settlement_discount)
        const totalPaidAmount = receiptGenerals.reduce((total, item) => total + item.total_paid_amount, 0);
        return [
            [{ text: `${netAmount <= totalPaidAmount ? 'PAID' : 'DUE'}`, fillColor: '#DBE7F2', fontSize: 18 }],
        ]
    }

    const getSummaryBody = (total) => {
        return [
            [{ text: 'Total Amount', alignment: 'right' }, { text: commaFormat(total.amount), alignment: 'right' }],
            [{ text: 'Bill. Discount', alignment: 'right' }, { text: commaFormat(total.discount), alignment: 'right' }],
            [{ text: 'Settle. Discount', alignment: 'right' }, { text: commaFormat(total.settlement_discount), alignment: 'right' }],
            [{ text: 'Net Bill Amount', alignment: 'right' }, { text: commaFormat(total.amount - (total.discount + total.settlement_discount)), alignment: 'right' }]
        ]
    }

    const getFinalSummaryBody = (total, receiptGenerals) => {
        const netAmount = total.amount - (total.discount + total.settlement_discount)
        const totalPaidAmount = receiptGenerals.reduce((total, item) => total + item.total_paid_amount, 0);
        const billAmount = (netAmount - totalPaidAmount);
        return [
            [{ text: 'Net Bill Amount', alignment: 'right' }, { text: commaFormat(netAmount), alignment: 'right' }],
            [{ text: 'Total Paid', alignment: 'right' }, { text: commaFormat(totalPaidAmount), alignment: 'right' }],
            [{ text: 'Due / Return Amount', alignment: 'right' }, { text: commaFormat(billAmount), alignment: 'right' }]
        ]
    }

    const calculateAge = (birthday) => {
        const { year } = dateOfBarthConvertToAge(birthday)
        return `${year}y`
    }

    const capitalize = (gender) => {
        return gender.charAt(0).toUpperCase() + gender.slice(1);
    }

    const getTableBody = (service) => {
        const header = getTableHead();
        const row = getTableRow(service);
        return [
            header,
            ...row
        ]
    }

    const getTableRow = (service) => {
        const data = [];
        service.map((item) => {
            data.push([{ text: item.name, colSpan: 3, fillColor: '#DBE7F2' }]);
            item.child.map((el, index) => {
                const row = [
                    { text: el.name },
                    { text: commaFormat(el.total_amount), alignment: 'right' },
                    { text: (index == 0) ? commaFormat(calculateTotal(item.child)) : '', rowSpan: item.child.length, alignment: 'right' }
                ];
                data.push(row);
            })
        })
        return data
    }

    const getReceiptTableRow = (receiptGenerals) => {
        const data = [];
        receiptGenerals.map((item) => {
            const row = [
                { text: formatDateWithTime(item.created_at) },
                { text: item.voucher_no },
                { text: item.account_head.name },
                { text: item.receipt_reference },
                { text: item.created_user.name },
                { text: commaFormat(item.total_paid_amount), alignment: 'right' }
            ]
            data.push(row);
        })

        const totalRow = [
            { text: 'Total', alignment: 'right', colSpan: 5 }, '', '', '', '', { text: commaFormat(getTotalReceipt(receiptGenerals)), alignment: 'right' }
        ]

        data.push(totalRow);

        return data;
    }

    const calculateTotal = (child) => child.reduce((total, item) => total + item.total_amount, 0);

    const getTotalReceipt = (receiptGenerals) => receiptGenerals.reduce((total, item) => total + item.total_paid_amount, 0)

    const getTableHead = () => {
        return [
            { text: 'Particulars' },
            { text: 'Amount' , alignment: 'right' },
            { text: 'Total' , alignment: 'right' }
        ]
    }

    const getReceiptTableHead = () => {
        return [
            { text: 'MR Date & Time' },
            { text: 'MR No' },
            { text: 'Mode of payment' },
            { text: 'MoP Ref.' },
            { text: 'Receipt by' },
            { text: 'Receipt Amount' , alignment: 'right' }
        ]
    }

    return {
        exportToPDF
    }
}

export default ipdStatement;
