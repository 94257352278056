import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import useDate from '@/services/utils/day';
import handleHospital from "@/services/modules/hospital";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ipdStatementDetails = () => {
    const { commaFormat } = figureFormatter();
    const { dateOfBarthConvertToAge } = handleHospital();
    const { getHeader, getFooter } = pdfHeaderFooter();

    const exportToPDF = (company, patientDetails, saleMaster, userName, barcode, qrcode) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 100, 25, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(patientDetails, barcode),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Daily Sales Report'
            }
        }

        pushTable(saleMaster, doc)
        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const pushTable = (saleMaster, doc) => {
        saleMaster.map((item, index) => {
            var stack = [
                {
                    text: `Invoice No: ${item.bill_number}\t Inv Date: ${item.date}\t Bill created by: ${item.created_user.name}`,
                    margin: [0, 10, 0, 5]
                },
    
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', 'auto', 'auto', 'auto'],
                        body: getEachInvoiceTable(item.general)
                    },
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex === 0) ? '#f3f2f7' : null;
                        },
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        }
                    }
                },

                {
                    text: getTotalText(item),
                    margin: [0, 5, 0, 15]
                }
            ]
            doc.content.push(stack);
        })
    }

    const getTotalText = (item) => {
        return `Total Amount: ${commaFormat(item.total_amount)}\t Settl. Discount: ${commaFormat(item.total_discount)}\t Net Bill Amount: ${commaFormat(item.total_amount - item.total_discount)}\t Paid Amount: ${commaFormat(item.paid_amount)}\t	Due Amount: ${item.total_amount - item.paid_amount}`
    }

    const getEachInvoiceTable = (general) => {
        const header = getEachTableHead();
        const row = getEachTableRow(general);

        return [
            header,
            ...row
        ]
    }

    const getEachTableRow = (general) => {
        const data = [];
        general.map((item) => {
            const row = [
                { text: item.product.name },
                { text: item.description },
                { text: item.quantity },
                { text: commaFormat(item.rate), alignment: 'right' },
                { text: commaFormat(item.total_amount), alignment: 'right' }
            ]
            data.push(row);
        })
        return data;
    }

    const getEachTableHead = () => {
        return [
            { text: 'Item' },
            { text: 'Description' },
            { text: 'Qty' },
            { text: 'Unit Price' },
            { text: 'Amount' , alignment: 'right' }
        ]
    }

    const getContent = (patientDetails, barcode) => {
        return [
            {
                text: 'IPD BILL (Details)',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },

            {
                text: `Bill Category : ${patientDetails.name}`,
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                fontSize: 14
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: `${patientDetails.patient.full_name ? 'Patient Name: ' + patientDetails.patient.full_name : ''}`
                            },
        
                            {
                                text: `${patientDetails.patient.patient_id ? 'Patient ID: ' + patientDetails.patient.patient_id : ''}`
                            },
        
                            {
                                text: `${calculateAge(patientDetails.patient.birthday)} ${patientDetails.patient.gender ? '| ' + capitalize(patientDetails.patient.gender) : ''}`
                            },
        
                            {
                                text: `${patientDetails.patient.mobile_no ? 'Phone No: ' + patientDetails.patient.mobile_no : ''}`
                            },
        
                            {
                                text: `${patientDetails.patient.full_address ? 'Patient Address: ' + patientDetails.patient.full_address : ''}`
                            }
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: `${patientDetails.admission_no ? 'IPD admission no: ' + patientDetails.admission_no : ''}`
                            },

                            {
                                text: `${patientDetails.admission_date ? 'Admission date: ' + patientDetails.admission_date : ''}`
                            },

                            {
                                text: `${patientDetails.department ? 'Admission Dept.: ' + patientDetails.department : ''}`
                            },

                            {
                                text: `${patientDetails.service_resource.name ? 'Bed/Cabin no: ' + patientDetails.service_resource.name : ''}`
                            },

                            {
                                text: `${patientDetails.human_resource.name ? 'Consultant: ' + patientDetails.human_resource.name : ''}`
                            }
                        ]
                    }
                ]
            }
        ]
    }

    const calculateAge = (birthday) => {
        const { year } = dateOfBarthConvertToAge(birthday)
        return `${year}y`
    }

    const capitalize = (gender) => {
        return gender.charAt(0).toUpperCase() + gender.slice(1);
    }

    return {
        exportToPDF
    }
}

export default ipdStatementDetails;