import ipdStatementDetails from '@/services/utils/pdf/ipdStatementDetails';
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';

const ipdStatementDetailsHelper = () => {
    const { exportToPDF } = ipdStatementDetails();
    const { fetchBarcode } = handleBarcode();
    const store = useStore();
    const { formatDate } = useDate();
    const userName = store.state.user.user.name;
    var barcode = '', qrcode = '';

    const generatePdf = (company, patientDetails, saleMaster) => {
        var dataArray = [];
        for (var key in saleMaster) {
            if (saleMaster.hasOwnProperty(key)) {
                dataArray.push(saleMaster[key]);
            }
        }

        let qrcodeData = JSON.stringify({
            ref_no: patientDetails.admission_no,
            ref_date: patientDetails.admission_date,
            created_by: userName,
            created_date_time: formatDate(new Date())
        })

        var barCodeQuery = `?code=${patientDetails.patient.patient_id}&qrcode=${qrcodeData}`;

        Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                barcode = res.barcode
                qrcode = res.qrcode;
            })
        ]).then(() => {
            exportToPDF(company, patientDetails, dataArray, userName, barcode, qrcode)
        }).catch((e) => {
            console.log(e);
        })
    }

    return{
        generatePdf
    }
}

export default ipdStatementDetailsHelper;